<template>
  <div class="content">
    <div class="box-content">
      <previsao-de-producao-header @filter="filter" />
      <div class="list-component">
        <div>
          <b-row no-gutters>
            <b-col
              lg="12"
              xl="12"
              class="list-titles mx-auto my-auto d-flex justify-content-center d-lg-none d-xl-none"
            >
              <li><b>Listagem de Previsão de Produção </b></li>
            </b-col>
            <b-col
              lg="1"
              xl="1"
              class="list-titles my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Pedido</b></li>
            </b-col>
            <b-col
              lg="3"
              xl="3"
              class="list-titles my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Cliente / Dt. de Prod.</b></li>
            </b-col>
            <b-col
              lg="2"
              xl="2"
              class="list-titles my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>SKU</b></li>
            </b-col>
            <b-col
              lg="2"
              xl="2"
              class="list-titles my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Transportadora</b></li>
            </b-col>
            <b-col
              lg="2"
              xl="2"
              class="list-titles border-none my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block text-truncate"
            >
              <li><b>Coleta</b></li>
            </b-col>
            <b-col
              lg="2"
              xl="2"
              class="list-titles my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block text-truncate"
            >
              <li><b>Qtd. Painéis</b></li>
            </b-col>
          </b-row>
        </div>
      </div>
      <div>
        <div
          v-if="loading"
          class="pad mt-5"
          style="text-align: center !important"
        >
          <b-spinner style="width: 10rem; height: 10rem" />
        </div>
        <div v-for="(baia, index) in baias" :key="index" class="my-3">
          <previsao-de-producao-list :baia="baia" />
        </div>
        <div v-if="baias.length == 0 && !loading" class="text-center mt-3">
          <b-col>
            <span>Não há previsão de produção.</span>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrevisaoDeProducaoHeader from "../components/PrevisaoDeProducaoHeader.vue";
import PrevisaoDeProducaoList from "../components/PrevisaoDeProducaoList.vue";
import PrevisaoProducaoService from "../components/service/PrevisaoDeProducaoService";

export default {
  name: "PrevisaoDeProducaoView",
  components: {
    PrevisaoDeProducaoHeader,
    PrevisaoDeProducaoList,
  },
  data: () => ({
    previsaoProducaoService: PrevisaoProducaoService.build(),
    endpoint: "pedidos",
    loading: false,
    baias: [],
    page: 1,
    modalAlert: {
      message: "",
      variant: "",
    },
  }),
  // created() {
  //   this.previsaoProducaoService = PrevisaoProducaoService.build().setVm(this);
  // },
  async mounted() {
    this.previsaoProducaoService.setVm(this);
    this.getPedidos();
  },
  methods: {
    async getPedidos() {
      await this.previsaoProducaoService.getListPedidos(this.page);
    },
    async filter(filtros) {
      await this.previsaoProducaoService.filter(filtros);
    },
  },
};
</script>

<style scoped>
.list-titles {
  list-style-type: none;
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
  padding: 0.8rem;
}
.border-none {
  list-style-type: none;
  padding: 0.8rem;
}
li {
  font-weight: normal;
  font-size: 1rem;
}
.list-component {
  background-color: #120947;
  color: #ffffff;
}
</style>
