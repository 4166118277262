<template>
  <div class="listing-header">
    <b-row class="mt-4 justify-content-between d-flex justify-content-end">
      <b-col
        cols="10"
        sm="9"
        md="9"
        lg="9"
        xl="10"
        class="justify-content-end d-flex"
      >
        <!-- <b-button
          v-if="role == 'ADMIN' || role == 'supervisor'"
          class="text-centered-button definitionBtn d-flex justify-content-end"
        >
          Relatório
        </b-button> -->
      </b-col>
      <b-col
        cols="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        class="d-flex justify-content-end"
      >
        <filter-list @filter="filter" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import FilterList from "@/shared/components/FilterList.vue";
export default {
  data: () => ({
    filtros: {
      numeroPedido: "",
      transportadora: "",
      role: "",
      periodo: {
        dataInicio: "",
        dataFinal: "",
      },
    },
  }),
  beforeMount() {
    this.getRoles();
  },
  components: {
    FilterList,
  },
  methods: {
    filter(filter) {
      this.$emit("filter", filter);
    },
    getRoles() {
      this.role = window.localStorage.getItem("roles");
    },
  },
};
</script>
<style scoped>
.listing-title {
  font-family: inherit;
  font-weight: bold;
  color: #002233;
}

.title-color {
  color: #002233;
}

.listing-header {
  margin-bottom: 3.1rem;
}
.definitionBtn {
  background-color: #e67a38;
  font-weight: bold;
  color: #012233;
  border: none;
}
.definitionBtn:hover {
  font-weight: bold;
  color: white;
  border: none;
}
.text-centered-button {
  display: inline-flex;
  align-items: center;
  height: 42px;
  border-radius: 7.5px;
}
</style>
