import { HttpRestService } from "@/services/http/HttpRestService";

export default class PrevisaoProducaoRepository {
  static build() {
    return new PrevisaoProducaoRepository();
  }

  async requestListPedidos(params) {
    return await HttpRestService.get("/api/v2/pedido/previsao-grupos", params);
  }
}
