<template>
  <b-dropdown
    size=""
    class="px-0 bg-transparent"
    no-caret
    text="Flitros"
    dropleft
  >
    <template #button-content>
      <b-icon icon="filter" class="mr-2"></b-icon>
      <span class="font-weight-bold">Filtros</span>
    </template>
    <b-dropdown-form class="custom-dropdown">
      <b-form-group label="Pedido" label-for="numeroPedido">
        <b-form-input
          v-model="filterParams.numeroPedido"
          id="numeroPedido"
          placeholder="n° pedido"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Transportadora" label-for="transportadora">
        <b-form-input
          v-model="filterParams.transportadoraNome"
          id="transportadora"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Periodo">
        <b-row>
          <b-col cols="6" class="px-2">
            <b-form-input
              type="date"
              v-model="filterParams.dataInicio"
            ></b-form-input>
          </b-col>
          <b-col cols="6" class="px-2">
            <b-form-input
              type="date"
              v-model="filterParams.dataFinal"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>
      <b-button class="custom-btn" variant="primary" @click="filter"
        >Filtrar</b-button
      >
    </b-dropdown-form>
  </b-dropdown>
</template>
<script>
export default {
  data: () => ({
    color: "#cc5d02",

    filterParams: {
      numeroPedido: "",
      transportadoraNome: "",
      dataInicio: "",
      dataFinal: "",
    },
  }),
  methods: {
    filter() {
      this.$emit("filter", this.filterParams);
    },
  },
};
</script>

<style scoped>
.custom-dropdown {
  width: 300px;
}

::v-deep .btn-secondary {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
  background-color: #cc5d02 !important;
  border-color: none !important;
}
::v-deep .btn-secondary:hover {
  background-color: #b45303 !important;
}
</style>
