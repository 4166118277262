import { render, staticRenderFns } from "./PrevisaoDeProducaoList.vue?vue&type=template&id=793d139c&scoped=true&"
import script from "./PrevisaoDeProducaoList.vue?vue&type=script&lang=js&"
export * from "./PrevisaoDeProducaoList.vue?vue&type=script&lang=js&"
import style0 from "./PrevisaoDeProducaoList.vue?vue&type=style&index=0&id=793d139c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793d139c",
  null
  
)

export default component.exports