<template>
  <div>
    <div class="listing-card mt-2">
      <b-row no-gutters>
        <b-col
          lg="10"
          xl="10"
          class="font-weight-bold list-titles d-lg-block d-xl-block listing-section-bar justify-content-start"
        >
          <li>
            <span>{{ baia.descricaoInsumo }}</span>
            - Qtd. Pallets: <span>&nbsp;{{ baia.quantidadePallets }}</span>
          </li>
        </b-col>
        <b-col
          lg="2"
          xl="2"
          class="font-weight-bold d-lg-block d-xl-block listing-section justify-content-end align-items-end d-flex"
        >
          <li>
            <span>{{ baia.qtdPaineis }}</span>
          </li>
        </b-col>
      </b-row>
    </div>
    <div
      v-for="(pedido, index) in baia.pedidos"
      :key="index"
      class="listing-card-list mt-1"
    >
      <b-row no-gutters>
        <b-col sm="1" md="1" lg="1" xl="1" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Número do pedido:</b>
            </span>
            <span class="d-flex align-items-center justify-content-center">{{
              pedido.numeroPedido
            }}</span>
          </li>
        </b-col>
        <b-col sm="3" md="3" lg="3" xl="3" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Cliente / Dt. de Prod.: </b>
            </span>
            <span class="d-flex align-items-center justify-content-center">
              {{ pedido.cliente }}</span
            ><br />
            <span class="d-flex align-items-center justify-content-center">{{
              pedido.dataProducao | formatDate
            }}</span
            ><br />
            <span class="d-flex align-items-center justify-content-center">
              {{ pedido.dataProducao | formatHour }}</span
            >
          </li>
        </b-col>
        <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> SKU: </b>
            </span>
            <span class="d-flex align-items-center justify-content-center">{{
              pedido.descricaoInsumo
            }}</span>
          </li>
        </b-col>
        <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Transportadora: </b>
            </span>
            <span class="d-flex align-items-center justify-content-center">
              {{ pedido.transportadoraNome }}
            </span>
          </li>
        </b-col>
        <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Coleta: </b>
            </span>
            <div v-if="pedido.coletaId">
              <span class="d-flex align-items-center justify-content-center">{{
                pedido.coletaId
              }}</span>
              <br />
              <span class="d-flex align-items-center justify-content-center">{{
                pedido.dataColeta | formatDate
              }}</span
              ><br />
              <span class="d-flex align-items-center justify-content-center">
                {{ pedido.dataColeta | formatHour }}</span
              >
            </div>
            <div v-else>
              <span> Não há coleta disponível </span>
            </div>
          </li>
        </b-col>
        <b-col sm="2" md="2" lg="2" xl="2" class="listing-section-bar">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Qtd.Painéis: </b>
            </span>
            <span class="d-flex align-items-center justify-content-center">
              {{ pedido.qtdPaineis }}
            </span>
          </li>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    baia: { Type: Object, required: true },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      if (!dateToBeFormatted) {
        return "";
      }

      const date = new Date(dateToBeFormatted);

      if (isNaN(date.getTime())) {
        return "";
      }

      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return formatter.format(date);
    },
  },
};
</script>
<style scoped>
.listing-section {
  list-style-type: none;
  border-right: 0.063rem solid rgba(83, 83, 83, 0.14);
  padding: 1.25rem;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}

.listing-section-bar {
  list-style-type: none;
  padding: 1.25rem;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}

.listing-card {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: #e3e5ec;
  color: #2e2e2e;
}
.listing-card:hover {
  background-color: rgba(202, 207, 211, 0.84);
  transition: 300ms all ease-in-out;
}

.listing-card-list {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: #eef0f8;
  color: #2e2e2e;
}
.listing-card-list:hover {
  background-color: rgba(215, 219, 223, 0.84);
  transition: 300ms all ease-in-out;
}
</style>
