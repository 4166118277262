import PrevisaoProducaoRepository from "../../../../../repositories/v2/PrevisaoproducaoRepository";

export default class PrevisaoProducaoService {
  vm;
  status;

  previsaoProducaoRepository = PrevisaoProducaoRepository.build();

  constructor(PrevisaoProducaoRepository) {
    this.previsaoProducaoRepository = PrevisaoProducaoRepository;
  }

  static build() {
    const previsaoProducaoRepository = PrevisaoProducaoRepository.build();
    return new PrevisaoProducaoService(previsaoProducaoRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async getListPedidos(page) {
    this.vm.loading = true;
    this.vm.baias = [];

    const body = {
      page: page,
      perPage: 10,
    };
    this.vm.pagination = {};
    try {
      const response = await this.previsaoProducaoRepository.requestListPedidos(
        body
      );

      this.insertResponse(response);
    } catch (e) {
      this.vm.loading = false;
    }
  }

  async filter(urlParams) {
    this.vm.baias = [];
    this.vm.loading = true;
    const queryStrings = {
      page: 1,
      perPage: 10,
      dataInicio: urlParams.dataInicio ?? null,
      dataFinal: urlParams.dataFinal ?? null,
      transportadoraNome: urlParams.transportadora ?? null,
      numeroPedido: urlParams.numeroPedido ?? null,
    };
    try {
      const response = await this.previsaoProducaoRepository.requestListPedidos(
        queryStrings
      );

      this.insertResponse(response);
    } catch (e) {
      this.vm.loading = false;
    }
  }

  insertResponse(response) {
    if (response.status !== 200) {
      this.vm.modalAlert = {
        message:
          "Algo de errado aconteceu com a listagem,favor recarregar a pagina, se o erro persistir entre em contato com o suporte.",
        variant: "danger",
      };
      this.vm.modalAlert = {
        message: response.data.message,
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-alert");
      this.vm.loading = false;
      this.vm.pedidos = [];
      return false;
    } else {
      const { data } = response.data;
      this.vm.baias = data;
      this.vm.loading = false;
    }
  }
}
